import React from 'react';
import ReactBootstrapCarousel from "react-bootstrap-carousel";
import Carousel from 'react-bootstrap/Carousel';

export default function Slider() {
return (
    <div style={{ display: 'block' }}>
    <Carousel>
        <Carousel.Item >
       
        <img
            className="d-block w-100 mb-5"
            src="assets/images/review1.png"
            alt="Image One"
        />
        
        </Carousel.Item>
         <Carousel.Item >
       
        <img
            className="d-block w-100 mb-5"
            src="assets/images/review2.png"
            alt="Image One"
        />
        
        </Carousel.Item>
        <Carousel.Item >
       
        <img
            className="d-block w-100 mb-5"
            src="assets/images/review3.png"
            alt="Image One"
        />
        
        </Carousel.Item>
        <Carousel.Item >
       
        <img
            className="d-block w-100 mb-5"
            src="assets/images/review4.png"
            alt="Image One"
        />
        
        </Carousel.Item>
        <Carousel.Item >
       
        <img
            className="d-block w-100 mb-5"
            src="assets/images/review5.png"
            alt="Image One"
        />
        
        </Carousel.Item>
        <Carousel.Item >
       
        <img
            className="d-block w-100 mb-5"
            src="assets/images/review6.png"
            alt="Image One"
        />
        
        </Carousel.Item>
        <Carousel.Item >
       
        <img
            className="d-block w-100 mb-5"
            src="assets/images/review7.png"
            alt="Image One"
        />
        
        </Carousel.Item>
        <Carousel.Item >
       
        <img
            className="d-block w-100 mb-5"
            src="assets/images/review8.png"
            alt="Image One"
        />
        
        </Carousel.Item>
        <Carousel.Item >
       
        <img
            className="d-block w-100 mb-5"
            src="assets/images/review9.png"
            alt="Image One"
        />
        
        </Carousel.Item>
        <Carousel.Item >
       
        <img
            className="d-block w-100 mb-5"
            src="assets/images/review10.png"
            alt="Image One"
        />
        
        </Carousel.Item>
        <Carousel.Item >
       
        <img
            className="d-block w-100 mb-5"
            src="assets/images/review11.png"
            alt="Image One"
        />
        
        </Carousel.Item>
        <Carousel.Item >
       
        <img
            className="d-block w-100 mb-5"
            src="assets/images/review12.png"
            alt="Image One"
        />
        
        </Carousel.Item>
        <Carousel.Item >
       
        <img
            className="d-block w-100 mb-5"
            src="assets/images/review13.png"
            alt="Image One"
        />
        
        </Carousel.Item>
        <Carousel.Item >
       
        <img
            className="d-block w-100 mb-5"
            src="assets/images/review14.png"
            alt="Image One"
        />
        
        </Carousel.Item>
        <Carousel.Item >
       
        <img
            className="d-block w-100 mb-5"
            src="assets/images/review15.png"
            alt="Image One"
        />
        
        </Carousel.Item>
        <Carousel.Item >
       
        <img
            className="d-block w-100 mb-5"
            src="assets/images/review16.png"
            alt="Image One"
        />
        
        </Carousel.Item>
        <Carousel.Item >
       
        <img
            className="d-block w-100 mb-5"
            src="assets/images/review17.png"
            alt="Image One"
        />
        
        </Carousel.Item>
        <Carousel.Item >
       
        <img
            className="d-block w-100 mb-5"
            src="assets/images/review18.png"
            alt="Image One"
        />
        
        </Carousel.Item>
    </Carousel>
    </div>
);
}

                        
                                