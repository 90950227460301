import React, {useState,setState,useRef} from 'react';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import ReactBootstrapCarousel from "react-bootstrap-carousel";
import Carousel from 'react-bootstrap/Carousel';
import RevSlider, { Slide, Caption } from 'react-rev-slider';
import Slider from"./slider"; 

function Home() {
    const tawkMessengerRef = useRef();

    const handleMinimize = () => {
        tawkMessengerRef.current.minimize();
    };
    const onLoad = () => {
        console.log('onLoad works!');
    };
    const imageUrl = 'assets/images/logowhite2.png';
    const imageUrl1 = 'assets/images/logoclrnew.png';

		return (		  
			<>
      <TawkMessengerReact
                propertyId="658d5c7070c9f2407f83ff5b"
                widgetId="1hio32652"
                onLoad={onLoad}/>
			<nav className="navbar navbar-expand-lg navbar-dark site_navbar bg-dark site-navbar-light" id="site-navbar">
      <div className="container">
        <a className="navbar-brand" href="/"><img src="assets/images/whitelogo.png" alt="Free Template by colorlib.com" className="img-fluid"/></a>
         {/*<a className="navbar-brand" href="/"><img src="assets/images/logocolor.png" alt="Free Template by colorlib.com" className="img-fluid"/></a>*/}
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#site-nav" aria-controls="site-nav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="oi oi-menu"></span> Menu
        </button>

        <div className="collapse navbar-collapse" id="site-nav">
          <ul className="navbar-nav ml-auto">

            <li className="nav-item active"><a href="#section-home" className="nav-link">Home</a></li>
            <li className="nav-item"><a href="#section-about" className="nav-link">About</a></li>
            <li className="nav-item"><a href="#section-combo" className="nav-link">Combo</a></li>
            {/*<li className="nav-item"><a href="#section-offer" className="nav-link">Offer</a></li>
            <li className="nav-item"><a href="#section-menu" className="nav-link">Menu</a></li>
            <li className="nav-item"><a href="#section-news" className="nav-link">News</a></li>
            <li className="nav-item"><a href="#section-gallery" className="nafv-link">Gallery</a></li>*/}
            <li className="nav-item"><a href="#section-contact" className="nav-link">Contact</a></li>
            <li className="nav-item"><a href="#section-swiggy" className="nav-link">Swiggy</a></li>
          </ul>
        </div>
      </div>
    </nav>

    <section className="site-cover" style={{ 
      backgroundImage: 'url(assets/images/home1.jpg)'}} id="section-home">
      <div className="container">
        <div className="row align-items-center justify-content-center text-center site-vh-100">
          <div className="col-md-12">
            <h1 className="site-heading site-animate mb-3">Welcome To CSeven Restaurant</h1>
            <h2 className="h5 site-subheading  site-animate">The perfect spot for everyone</h2>    
            <h5 className="h5 site-subheading mb-5 site-animate">Get a complete dining experience</h5> 
            {/*<p><a href="https://colorlib.com/" target="_blank" className="btn btn-outline-white btn-lg site-animate" data-toggle="modal" data-target="#reservationModal">Reservation</a></p>*/}
          </div>
        </div>
      </div>
    </section>
    
    <section className="site-section" id="section-about">
      <div className="container">
        <div className="row">
          <div className="col-md-5 site-animate mb-5">
            <h4 className="site-sub-title">Our Story</h4>
            <h2 className="site-primary-title display-4">Welcome</h2>
            <p>The story of CSeven Restaurant is nothing short of an interesting movie plot. We were a dynamic team of techies who had sailed in the developing field for more than 20+ years. But then we felt the need for a fresh breath of air. Our fingers had been touching keyboards for so long, but what we truly yearned for was to touch your souls through your tastebuds. So, without further ado, we took a leap of faith into the culinary industry. CSeven Restaurant aims to not just serve great food but great experiences that you can take back home.</p>

            <p className="mb-4">20+ years of experience in technology
But Always had a thought for F&B to touch the soul of the human. Have been playing with the computer keyboards for fulfilling the technology needs but always wanted to play with the taste buds to fulfill the soul requirements. So we decided to get started with our dreams.</p>
            {/*<p><a href="#" className="btn btn-secondary btn-lg">Learn More About Us</a></p>*/}
          </div>
          <div className="col-md-1"></div>
          <div className="col-md-6 site-animate mt-5 img" data-animate-effect="fadeInRight">
            <img src="assets/images/ckn2.jpg" alt="Free Template by colorlib.com" className="img-fluid"/>
          </div>
        </div>
      </div>
    </section>
    

    <section className="site-section bg-light" id="section-combo">
      <div className="container">
        
        <div className="row">
          <div className="col-md-12 text-center mb-5 site-animate">
            <h4 className="site-sub-title">Combo Mania</h4>
            <h2 className="display-4">Food that fits together, tastes better!</h2>
            <div className="row justify-content-center">
              <div className="col-md-7">
                <p className="lead">Try out our mouth-watering food combinations where we mix and match some of our best dishes that taste simply incredible together.</p>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="owl-carousel site-owl">

              <div className="item">
                <div className="media d-block mb-4 text-center site-media site-animate border-0">
                  <img src="assets/images/burger.jpg" alt="Free Template by colorlib.com" className="img-fluid"/>
                  <div className="media-body p-md-5 p-4">
                    <h5 className="text-primary">Rs.310</h5>
                    <h5 className="mt-0 h4">Burger Combo</h5>
                    <p className="mb-4">Brace yourself for a  burst of flavors with our juicy and saucy chicken burger coupled with a tender paneer tikka roll marinated in a fiery masala and finally a refreshing mint mojito to water it all down.</p>

                    {/*<p className="mb-0"><a href="#" className="btn btn-primary btn-sm">Order Now!</a></p>*/}
                  </div>
                </div>
              </div>

              <div className="item">
                <div className="media d-block mb-4 text-center site-media site-animate border-0">
                  <img src="assets/images/shawarma.jpg" alt="Free Template by colorlib.com" className="img-fluid"/>
                  <div className="media-body p-md-5 p-4">
                    <h5 className="text-primary">Rs.400</h5>
                    <h5 className="mt-0 h4">Shawarma Combo</h5>
                    <p className="mb-4">A quarter of our BBQ chicken mingling with a zesty Mexican sandwich, while a spicy Schezwan shawarma adds a kick to the mix. Additionally, a refreshing green apple crush that ties it all together. </p>

                    {/*<p className="mb-0"><a href="#" className="btn btn-primary btn-sm">Order Now!</a></p>*/}
                  </div>
                </div>
              </div>
              
              <div className="item">
                <div className="media d-block mb-4 text-center site-media site-animate border-0">
                  <img src="assets/images/vegsw.jpg" alt="Free Template by colorlib.com" className="img-fluid"/>
                  <div className="media-body p-md-5 p-4">
                    <h5 className="text-primary">Rs.360</h5>
                    <h5 className="mt-0 h4">Veg Combo</h5>
                    <p className="mb-4">A plate with a generous serving of creamy paneer, an unparalley juicy veg burger, and perfectly steamed veg momos bursting with flavors. But wait, there's more! a reviving mint mojito to quench your thirst.</p>

                    {/*<p className="mb-0"><a href="#" className="btn btn-primary btn-sm">Order Now!</a></p>*/}
                  </div>
                </div>
              </div>

              <div className="item">
                <div className="media d-block mb-4 text-center site-media site-animate border-0">
                  <img src="assets/images/moji.jpg" alt="Free Template by colorlib.com" className="img-fluid"/>
                  <div className="media-body p-md-5 p-4">
                    <h5 className="text-primary">Rs.610</h5>
                    <h5 className="mt-0 h4">TGT Combo</h5>
                    <p className="mb-4">Half of our fiery grilled chicken with a scrumptious chicken burger and a bursting-with-flavor shawarma. Enhancing these smoky and savory notes is our perfectly crafted mint mojito. </p>

                    {/*<p className="mb-0"><a href="#" className="btn btn-primary btn-sm">Order Now!</a></p>*/}
                  </div>
                </div>
              </div>

              <div className="item">
                <div className="media d-block mb-4 text-center site-media site-animate border-0">
                  <img src="assets/images/friedckn.jpg" alt="Free Template by colorlib.com" className="img-fluid"/>
                  <div className="media-body p-md-5 p-4">
                    <h5 className="text-primary">Rs.430</h5>
                    <h5 className="mt-0 h4">Kiddy Combo</h5>
                    <p className="mb-4">Prepare your taste buds for our mouthwatering combination of 2 crispy fried chicken pieces, golden french fries, irresistible chicken popcorn, and the heavenly choco oreo shake.</p>

                    {/*<p className="mb-0"><a href="#" className="btn btn-primary btn-sm">Order Now!</a></p>*/}
                  </div>
                </div>
              </div>
              
              <div className="item">
                <div className="media d-block mb-4 text-center site-media site-animate border-0">
                  <img src="assets/images/ckn1.jpg" alt="Free Template by colorlib.com" className="img-fluid"/>
                  <div className="media-body p-md-5 p-4">
                    <h5 className="text-primary">Rs.480</h5>
                    <h5 className="mt-0 h4">Combo For Two</h5>
                    <p className="mb-4">2 pieces of perfectly crispy, golden-brown fried chicken popcorn, coupled with a juicy fried chicken burger exploding in flavous which you can wash it down with our refreshing mojito. </p>

                    {/*<p className="mb-0"><a href="#" className="btn btn-primary btn-sm">Order Now!</a></p>*/}
                  </div>
                </div>
              </div>

              <div className="item">
                <div className="media d-block mb-4 text-center site-media site-animate border-0">
                  <img src="assets/images/ckn2.jpg" alt="Free Template by colorlib.com" className="img-fluid"/>
                  <div className="media-body p-md-5 p-4">
                    <h5 className="text-primary">Rs.610</h5>
                    <h5 className="mt-0 h4">PUBG Combo</h5>
                    <p className="mb-4">Get not 1 but 3 pieces of our seasoned fried chicken coupled with our juicy chicken wings, and popcorn chicken which offer a burst of flavor with every bite. Finish with our sweet bubblegum blast.</p>

                    {/*<p className="mb-0"><a href="#" className="btn btn-primary btn-sm">Order Now!</a></p>*/}
                  </div>
                </div>
              </div>

              <div className="item">
                <div className="media d-block mb-4 text-center site-media site-animate border-0">
                  <img src="assets/images/ckn1.jpg" alt="Free Template by colorlib.com" className="img-fluid"/>  
                  <div className="media-body p-md-5 p-4">
                    <h5 className="text-primary">Rs.690</h5>
                    <h5 className="mt-0 h4">Trendy Combo</h5>
                    <p className="mb-4">5 pieces of crispy fried chicken served alongside our burger which includes tender chicken, crunchy veggies, and zesty sauce. Don’t miss our flavourful chicken roll paired with nutto coffee shake. </p>

                    {/*<p className="mb-0"><a href="#" className="btn btn-primary btn-sm">Order Now!</a></p>*/}
                  </div>
                </div>
              </div>
              
              <div className="item">
                <div className="media d-block mb-4 text-center site-media site-animate border-0">
                  <img src="assets/images/moji.jpg" alt="Free Template by colorlib.com" className="img-fluid"/>
                  <div className="media-body p-md-5 p-4">
                    <h5 className="text-primary">Rs.1130</h5>
                    <h5 className="mt-0 h4">Family Combo</h5>
                    <p className="mb-4">5 pieces of crispy fried chicken and 5 boneless stripes paired with our fried chicken burger for those seeking an extra dose of indulgence. The refreshing zing of two mint mojitos further elevates the experience. </p>

                    {/*<p className="mb-0"><a href="#" className="btn btn-primary btn-sm">Order Now!</a></p>*/}
                  </div>
                </div>
              </div>

            </div>
          </div>
          
        </div>
      </div>
    </section>

    {/*<section className="site-section" id="section-menu">
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center mb-5 site-animate">
            <h2 className="display-4">Delicious Menu</h2>
            <div className="row justify-content-center">
              <div className="col-md-7">
                <p className="lead">Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.</p>
              </div>
            </div>
          </div>

          <div className="col-md-12 text-center">

            <ul className="nav site-tab-nav nav-pills mb-5" id="pills-tab" role="tablist">
              <li className="nav-item site-animate">
                <a className="nav-link active" id="pills-veg-tab" data-toggle="pill" href="#pills-veg" role="tab" aria-controls="pills-veg" aria-selected="true">Veg</a>
              </li>
              <li className="nav-item site-animate">
                <a className="nav-link" id="pills-nonveg-tab" data-toggle="pill" href="#pills-nonveg" role="tab" aria-controls="pills-nonveg" aria-selected="false">Non-Veg</a>
              </li>
              <li className="nav-item site-animate">
                <a className="nav-link" id="pills-signdish-tab" data-toggle="pill" href="#pills-signdish" role="tab" aria-controls="pills-signdish" aria-selected="false">Signature Dish</a>
              </li>
            </ul>

            <div className="tab-content text-left">
              <div className="tab-pane fade show active" id="pills-veg" role="tabpanel" aria-labelledby="pills-veg-tab">
                <div className="row">
                  <div className="col-md-6 site-animate">
                    <div className="media menu-item">
                      <img className="mr-3" src="assets/images/menu_1.jpg" className="img-fluid" alt="Free Template by colorlib.com"/>
                      <div className="media-body">
                        <h5 className="mt-0">Veg Momos</h5>
                        <p> Fried/Steamed </p>
                        <h6 className="text-primary menu-price">Rs.90</h6>
                      </div>
                    </div>

                    <div className="media menu-item">
                      <img className="mr-3" src="assets/images/menu_2.jpg" className="img-fluid" alt="Free Template by colorlib.com"/>
                      <div className="media-body">
                        <h5 className="mt-0">Paneer Momos</h5>
                        <p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.</p>
                        <h6 className="text-primary menu-price">Rs.100</h6>
                      </div>
                    </div>

                    <div className="media menu-item">
                      <img className="mr-3" src="assets/images/menu_3.jpg" className="img-fluid" alt="Free Template by colorlib.com"/>
                      <div className="media-body">
                        <h5 className="mt-0">Corn Cheese Sandwich</h5>
                        <p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.</p>
                        <h6 className="text-primary menu-price">Rs.115</h6>
                      </div>
                    </div>

                  </div>
                  <div className="col-md-6 site-animate">
                    <div className="media menu-item">
                      <img className="mr-3" src="assets/images/menu_2.jpg" className="img-fluid" alt="Free Template by colorlib.com"/>
                      <div className="media-body">
                        <h5 className="mt-0">Paneer Cheese Sandwich</h5>
                        <p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.</p>
                        <h6 className="text-primary menu-price">Rs.125</h6>
                      </div>
                    </div>

                    <div className="media menu-item">
                      <img className="mr-3" src="assets/images/menu_1.jpg" className="img-fluid" alt="Free Template by colorlib.com"/>
                      <div className="media-body">
                        <h5 className="mt-0">Chocolate Sandwich</h5>
                        <p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.</p>
                        <h6 className="text-primary menu-price">Rs.110</h6>
                      </div>
                    </div>

                    <div className="media menu-item">
                      <img className="mr-3" src="assets/images/menu_3.jpg" className="img-fluid" alt="Free Template by colorlib.com"/>
                      <div className="media-body">
                        <h5 className="mt-0">C7 Cheesy Tower Burger</h5>
                        <p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.</p>
                        <h6 className="text-primary menu-price">Rs.180</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tab-pane fade show active" id="pills-veg" role="tabpanel" aria-labelledby="pills-veg-tab">
                <div className="row">
                  <div className="col-md-6 site-animate">
                    <div className="media menu-item">
                      <img className="mr-3" src="assets/images/menu_1.jpg" className="img-fluid" alt="Free Template by colorlib.com"/>
                      <div className="media-body">
                        <h5 className="mt-0">Veg Burger</h5>
                        <p> Fried/Steamed </p>
                        <h6 className="text-primary menu-price">Rs.110</h6>
                      </div>
                    </div>

                    <div className="media menu-item">
                      <img className="mr-3" src="assets/images/menu_2.jpg" className="img-fluid" alt="Free Template by colorlib.com"/>
                      <div className="media-body">
                        <h5 className="mt-0">Veg Wrap</h5>
                        <p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.</p>
                        <h6 className="text-primary menu-price">Rs.110</h6>
                      </div>
                    </div>

                    <div className="media menu-item">
                      <img className="mr-3" src="assets/images/menu_3.jpg" className="img-fluid" alt="Free Template by colorlib.com"/>
                      <div className="media-body">
                        <h5 className="mt-0">Veg Clear Soup</h5>
                        <p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.</p>
                        <h6 className="text-primary menu-price">Rs.60</h6>
                      </div>
                    </div>

                  </div>
                  <div className="col-md-6 site-animate">
                    <div className="media menu-item">
                      <img className="mr-3" src="assets/images/menu_2.jpg" className="img-fluid" alt="Free Template by colorlib.com"/>
                      <div className="media-body">
                        <h5 className="mt-0">Mushroom Soup</h5>
                        <p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.</p>
                        <h6 className="text-primary menu-price">Rs.65</h6>
                      </div>
                    </div>

                    <div className="media menu-item">
                      <img className="mr-3" src="assets/images/menu_1.jpg" className="img-fluid" alt="Free Template by colorlib.com"/>
                      <div className="media-body">
                        <h5 className="mt-0">Corn Soup</h5>
                        <p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.</p>
                        <h6 className="text-primary menu-price">Rs.65</h6>
                      </div>
                    </div>

                    <div className="media menu-item">
                      <img className="mr-3" src="assets/images/menu_3.jpg" className="img-fluid" alt="Free Template by colorlib.com"/>
                      <div className="media-body">
                        <h5 className="mt-0">C7 Cheesy Tower Burger</h5>
                        <p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.</p>
                        <h6 className="text-primary menu-price">Rs.180</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tab-pane fade" id="pills-nonveg" role="tabpanel" aria-labelledby="pills-nonveg-tab">
                <div className="row">
                  <div className="col-md-6 site-animate">

                    <div className="media menu-item">
                      <img className="mr-3" src="assets/images/menu_3.jpg" className="img-fluid" alt="Free Template by colorlib.com"/>
                      <div className="media-body">
                        <h5 className="mt-0">Veg Burger</h5>
                        <p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.</p>
                        <h6 className="text-primary menu-price">Rs.110</h6>
                      </div>
                    </div>

                    <div className="media menu-item">
                      <img className="mr-3" src="assets/images/menu_1.jpg" className="img-fluid" alt="Free Template by colorlib.com"/>
                      <div className="media-body">
                        <h5 className="mt-0">Veg Wrap</h5>
                        <p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.</p>
                        <h6 className="text-primary menu-price">Rs.110</h6>
                      </div>
                    </div>

                    <div className="media menu-item">
                      <img className="mr-3" src="assets/images/menu_2.jpg" className="img-fluid" alt="Free Template by colorlib.com"/>
                      <div className="media-body">
                        <h5 className="mt-0">Paneer Cheese Wrap</h5>
                        <p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.</p>
                        <h6 className="text-primary menu-price">$24.50</h6>
                      </div>
                    </div>

                  </div>
                  <div className="col-md-6 site-animate">

                    <div className="media menu-item">
                      <img className="mr-3" src="assets/images/menu_3.jpg" className="img-fluid" alt="Free Template by colorlib.com"/>
                      <div className="media-body">
                        <h5 className="mt-0">Fried Potato w/ Garlic</h5>
                        <p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.</p>
                        <h6 className="text-primary menu-price">$18.50</h6>
                      </div>
                    </div>

                    <div className="media menu-item">
                      <img className="mr-3" src="assets/images/menu_2.jpg" className="img-fluid" alt="Free Template by colorlib.com"/>
                      <div className="media-body">
                        <h5 className="mt-0">Italian Sauce Mushroom</h5>
                        <p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.</p>
                        <h6 className="text-primary menu-price">$35.50</h6>
                      </div>
                    </div>

                    <div className="media menu-item">
                      <img className="mr-3" src="assets/images/menu_1.jpg" className="img-fluid" alt="Free Template by colorlib.com"/>
                      <div className="media-body">
                        <h5 className="mt-0">Salted Fried Chicken</h5>
                        <p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.</p>
                        <h6 className="text-primary menu-price">$12.50</h6>
                      </div>
                    </div>

                    
                  </div>
                </div>
              </div>
              <div className="tab-pane fade" id="pills-signdish" role="tabpanel" aria-labelledby="pills-signdish-tab">
                <div className="row">
                  <div className="col-md-6 site-animate">

                    <div className="media menu-item">
                      <img className="mr-3" src="assets/images/menu_2.jpg" className="img-fluid" alt="Free Template by colorlib.com"/>
                      <div className="media-body">
                        <h5 className="mt-0">Italian Sauce Mushroom</h5>
                        <p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.</p>
                        <h6 className="text-primary menu-price">$24.50</h6>
                      </div>
                    </div>

                    <div className="media menu-item">
                      <img className="mr-3" src="assets/images/menu_1.jpg" className="img-fluid" alt="Free Template by colorlib.com"/>
                      <div className="media-body">
                        <h5 className="mt-0">Salted Fried Chicken</h5>
                        <p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.</p>
                        <h6 className="text-primary menu-price">$35.50</h6>
                      </div>
                    </div>

                    <div className="media menu-item">
                      <img className="mr-3" src="assets/images/menu_3.jpg" className="img-fluid" alt="Free Template by colorlib.com"/>
                      <div className="media-body">
                        <h5 className="mt-0">Fried Potato w/ Garlic</h5>
                        <p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.</p>
                        <h6 className="text-primary menu-price">$14.50</h6>
                      </div>
                    </div>

                  </div>
                  <div className="col-md-6 site-animate">

                    <div className="media menu-item">
                      <img className="mr-3" src="assets/images/menu_3.jpg" className="img-fluid" alt="Free Template by colorlib.com"/>
                      <div className="media-body">
                        <h5 className="mt-0">Fried Potato w/ Garlic</h5>
                        <p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.</p>
                        <h6 className="text-primary menu-price">$18.50</h6>
                      </div>
                    </div>

                    <div className="media menu-item">
                      <img className="mr-3" src="assets/images/menu_2.jpg" className="img-fluid" alt="Free Template by colorlib.com"/>
                      <div className="media-body">
                        <h5 className="mt-0">Italian Sauce Mushroom</h5>
                        <p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.</p>
                        <h6 className="text-primary menu-price">$35.50</h6>
                      </div>
                    </div>

                    <div className="media menu-item">
                      <img className="mr-3" src="assets/images/menu_1.jpg" className="img-fluid" alt="Free Template by colorlib.com"/>
                      <div className="media-body">
                        <h5 className="mt-0">Salted Fried Chicken</h5>
                        <p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.</p>
                        <h6 className="text-primary menu-price">$12.50</h6>
                      </div>
                    </div>

                    
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>

    <section className="site-section bg-light" id="section-news">
      <div className="container">

        <div className="row">
          <div className="col-md-12 text-center mb-5 site-animate">
            <h2 className="display-4">News</h2>
            <div className="row justify-content-center">
              <div className="col-md-7">
                <p className="lead">Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.</p>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="media d-block mb-4 text-center site-media site-animate">
              <img src="assets/images/offer_1.jpg" alt="Free Template by colorlib.com" className="img-fluid"/>
              <div className="media-body p-md-5 p-4">
                <h5 className="mt-0 h4">We Have Dilecious Food</h5>
                <p className="mb-4">Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.</p>

                <p className="mb-0"><a href="#" className="btn btn-primary btn-sm">Read More</a></p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="media d-block mb-4 text-center site-media site-animate">
              <img src="assets/images/offer_2.jpg" alt="Free Template by colorlib.com" className="img-fluid"/>
              <div className="media-body p-md-5 p-4">
                <h5 className="mt-0 h4">Chef Special Menu</h5>
                <p className="mb-4">Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.</p>

                <p className="mb-0"><a href="#" className="btn btn-primary btn-sm">Read More</a></p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="media d-block mb-4 text-center site-media site-animate">
              <img src="assets/images/offer_3.jpg" alt="Free Template by colorlib.com" className="img-fluid"/>
              <div className="media-body p-md-5 p-4">
                <h5 className="mt-0 h4">Merriage Celebrations</h5>
                <p className="mb-4">Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.</p>

                <p className="mb-0"><a href="#" className="btn btn-primary btn-sm">Read More</a></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="site-section" id="section-gallery">
      <div className="container">
        <div className="row site-custom-gutters">

          <div className="col-md-12 text-center mb-5 site-animate">
            <h2 className="display-4">Gallery</h2>
            <div className="row justify-content-center">
              <div className="col-md-7">
                <p className="lead">Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.</p>
              </div>
            </div>
          </div>

          <div className="col-md-4 site-animate">
            <a href="images/menu_1.jpg" className="site-thumbnail image-popup">
              <img src="assets/images/menu_1.jpg" alt="Free Template by colorlib.com" className="img-fluid"/>
            </a>
          </div>
          <div className="col-md-4 site-animate">
            <a href="images/menu_2.jpg" className="site-thumbnail image-popup">
              <img src="assets/images/menu_2.jpg" alt="Free Template by colorlib.com" className="img-fluid"/>
            </a>
          </div>
          <div className="col-md-4 site-animate">
            <a href="images/menu_3.jpg" className="site-thumbnail image-popup">
              <img src="assets/images/menu_3.jpg" alt="Free Template by colorlib.com" className="img-fluid"/>
            </a>
          </div>

          <div className="col-md-4 site-animate">
            <a href="images/menu_2.jpg" className="site-thumbnail image-popup">
              <img src="assets/images/menu_2.jpg" alt="Free Template by colorlib.com" className="img-fluid"/>
            </a>
          </div>
          <div className="col-md-4 site-animate">
            <a href="images/menu_3.jpg" className="site-thumbnail image-popup">
              <img src="assets/images/menu_3.jpg" alt="Free Template by colorlib.com" className="img-fluid"/>
            </a>
          </div>
          <div className="col-md-4 site-animate">
            <a href="images/menu_1.jpg" className="site-thumbnail image-popup">
              <img src="assets/images/menu_1.jpg" alt="Free Template by colorlib.com" className="img-fluid"/>
            </a>
          </div>

        </div>
      </div>
    </section>*/}


      
    <section className="site-section bg-light" id="section-combo">
      <div className="container">
        
        <div className="row">
          <div className="col-md-12 text-center mb-5 site-animate">
            <h2 className="display-4">Testimonials</h2>
            <div className="row justify-content-center">
              <div className="col-md-10">
                <p className="lead">Hear what our diners have to say about us!</p>
              </div>
            </div>
          </div>
        </div>
          <div className="row">
            <div className="col-md-12 text-center mb-5 site-animate">
              <div className="row justify-content-center">  
                <div className="col-md-10">
                    <Slider></Slider>
                  </div>
              </div>
            </div>
          </div>
      </div>
    </section>

        
      
      <section className="site-section bg-light" id="section-contact">
      <div className="container">
        <div className="row">

          <div className="col-md-12 text-center site-animate">
            <h2 className="display-4">Get In Touch</h2>
            <div className="row justify-content-center">
              <div className="col-md-7">
                <p className="lead">Have something you want to share with us? Or You feel like we could be friends? Don’t hesitate to drop a message below! </p>
              </div>
            </div>
          </div>

          <div className="col-md-2 mb-5 site-animate">
            
          </div>
          <div className="col-md-1"></div>
          <div className="col-md-6 site-animate">
            {/*<p><img src="assets/images/about_img_1.jpg" alt="" className="img-fluid"/></p>*/}
            <p className="lead" style={{color : "#000" , fontSize: "16px"}}>
              Address: <br/> 41/2A, P & T Nagar Main Rd, Bank Colony, Bama Nagar, Madurai, Tamil Nadu 625017, India 
              <br/> Madurai, Tamil Nadu 625017, India  
              <br/> 
              <br/>
              Phone: 
              <br/> +91 89258 40550
              <br/> 
              <br/>
              Email: <br/> <a href="mailto:contact@cseven.in">contact@cseven.in</a>
            </p>
            <p className="lead" style={{color : "#000" , fontSize: "16px"}}>You Can Text Us In Whatsapp By Hitting The Button &nbsp;:&nbsp;
            <a href="https://wa.me/+918925840550" target="_blank"><img  style={{"height" : "50px", "width" : "50px"}}src="assets/images/whatsapp.png" alt="" /></a></p>
          </div>
          <div className="col-md-1"></div>
          <div className="col-md-2 site-animate">
            
          </div>
          
        </div>
      </div>
    </section>
      
    <section className="site-section bg-light" id="section-swiggy">
      <div className="container">
        <div className="row">

          <div className="col-md-12 text-center mb-5 site-animate">
            <h2 className="display-4">Excellence</h2>
            <div className="row justify-content-center">
              <div className="col-md-7">
                <p className="lead">Wanna try something from home in a click??? Please Don't Search us on swiggy Just a click will directly take you to our Restaurant</p>
              </div>
            </div>
          </div>

          <div className="col-md-2 mb-5 site-animate">
            
          </div>
          <div className="col-md-1"></div>
          <div className="col-md-6 site-animate">
          <h5 className="text-primary text-center">Order Now</h5>
            <p className="text-center"><a  target="_blank" href="https://www.swiggy.com/menu/771094?source=sharing "><img src="assets/images/swiggy1.png" alt="" className="img-fluid"  /></a></p>
          </div>
          
              <div className="form-group">
              </div>
          <div className="col-md-1"></div>
          <div className="col-md-2 mb-5 site-animate">
            
          </div>
          
        </div>
      </div>
    </section>

    <footer className="site-footer site-bg-dark site-section">
      <div className="container">
        <div className="row mb-5">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-4 site-animate">
                <h2 className="site-heading-2">We are Live !</h2>
                <p><a  target="_blank" href="https://www.swiggy.com/menu/771094?source=sharing "><img src="assets/images/swiggy1.png" alt="" className="img-fluid"  /></a></p>              </div>
              <div className="col-md-1"></div>
              <div className="col-md-3 site-animate">
                 <div className="site-footer-widget mb-4">
                  <h2 className="site-heading-2">Business Hours</h2>
                  <ul className="list-unstyled">
                    <li className="py-2 d-block">All 7 Days : 11:00 AM - 10:00 PM</li>
                    <li className="py-2 d-block">Except Public Holidays</li>
                  </ul>
                </div>
              </div>
              <div className="col-md-1"></div>
              
              <div className="col-md-3 site-animate">
                <div className="site-footer-widget mb-4">
                  <h2 className="site-heading-2">Special Launch Of The Month</h2>
                  <ul className="list-unstyled">
                    <li><a href="https://www.instagram.com/p/C2RFc7-LFJd/?igsh=eXQ1a2duNnNpazB5" target="_blank" className="py-2 d-block">Kal Dosa , 2 Chutneys , & Our Signature Chicken Gravy</a></li>
                    <a href="https://www.instagram.com/p/C2RFc7-LFJd/?igsh=eXQ1a2duNnNpazB5" target="_blank" className="py-2 d-block"><img src="assets/images/dosalaunch.png" alt="" className="img-fluid"  /></a>                 
                  </ul>
                </div>
              </div>
              {/*<div className="col-md-2 site-animate">
                 <div className="site-footer-widget mb-4">
                  <h2 className="site-heading-2">Useful links</h2>
                  <ul className="list-unstyled">
                    <li><a href="#" className="py-2 d-block">Foods</a></li>
                    <li><a href="#" className="py-2 d-block">Drinks</a></li>
                    <li><a href="#" className="py-2 d-block">veg</a></li>
                    <li><a href="#" className="py-2 d-block">Brunch</a></li>
                    <li><a href="#" className="py-2 d-block">signdish</a></li>
                  </ul>
                </div>
              </div>*/}
            </div>
          </div>
         
        </div>
        <div className="row site-animate">
           <div className="col-md-12 text-center">
            <div className="site-footer-widget mb-4">
              <ul className="site-footer-social list-unstyled ">
                <li className="site-animate"><a href="https://www.youtube.com/@CsevenRestaurant" target="_blank"><span className="icon-youtube"></span></a></li>
                <li className="site-animate"><a href="https://instagram.com/c7_foods?igshid=NzZlODBkYWE4Ng==" target="_blank"><span className="icon-instagram"></span></a></li>
                <li className="site-animate"><a href="#"><span className="icon-facebook"></span></a></li>
              </ul>
            </div>
          </div>
          <div className="col-md-12 text-center">
            <p>&copy;2023 <script>document.write(new Date().getFullYear());</script> Cseven Restaurant | All rights reserved <i className="icon-heart" aria-hidden="true"></i></p>
          </div>
        </div>
      </div>
    </footer>

    <div className="modal fade" id="reservationModal" tabindex="-1" role="dialog" aria-labelledby="reservationModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-body">
            <div className="row">
              <div className="col-lg-12">
                <div className="bg-image" style={{ 
      backgroundImage: 'url(assets/images/reservation_1.jpg)'}}></div>
              </div>
              <div className="col-lg-12 p-5">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <small>CLOSE </small><span aria-hidden="true">&times;</span>
                </button>
                <h1 className="mb-4">Reserve A Table</h1>  
                <form action="#" method="post">
                  <div className="row">
                    <div className="col-md-6 form-group">
                      <label for="m_fname">First Name</label>
                      <input type="text" className="form-control" id="m_fname"/>
                    </div>
                    <div className="col-md-6 form-group">
                      <label for="m_lname">Last Name</label>
                      <input type="text" className="form-control" id="m_lname"/>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 form-group">
                      <label for="m_email">Email</label>
                      <input type="email" className="form-control" id="m_email"/>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 form-group">
                      <label for="m_people">How Many People</label>
                      <select name="" id="m_people" className="form-control">
                        <option value="1">1 People</option>
                        <option value="2">2 People</option>
                        <option value="3">3 People</option>
                        <option value="4+">4+ People</option>
                      </select>
                    </div>
                    <div className="col-md-6 form-group">
                      <label for="m_phone">Phone</label>
                      <input type="text" className="form-control" id="m_phone"/>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6 form-group">
                      <label for="m_date">Date</label>
                      <input type="text" className="form-control" id="m_date"/>
                    </div>
                    <div className="col-md-6 form-group">
                      <label for="m_time">Time</label>
                      <input type="text" className="form-control" id="m_time"/>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12 form-group">
                      <label for="m_message">Message</label>
                      <textarea className="form-control" id="m_message" cols="30" rows="7"></textarea>
                    </div>
                  </div>
                  
                  <div className="row">
                    <div className="col-md-12 form-group">
                      <input type="submit" className="btn btn-primary btn-lg btn-block" value="Reserve Now"/>
                    </div>
                  </div>

                </form>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>
    <div id="site-loader" className="show fullscreen"><svg className="circular" width="48px" height="48px"><circle className="path-bg" cx="24" cy="24" r="22" fill="none" stroke-width="4" stroke="#eeeeee"/><circle className="path" cx="24" cy="24" r="22" fill="none" stroke-width="4" stroke-miterlimit="10" stroke="#F96D00"/></svg></div>
			</>
		);
	}
  export default Home; 